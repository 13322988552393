@import '~bootstrap/dist/css/bootstrap.min.css';

/* IBMPlexSans-Regular */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local('IBMPlexSans-Regular'), local('IBMPlexSans-Regular'),
  url('../fonts/IBMPlexSans-Regular.woff') format('woff');
}

/* IBMPlexSans-Regular */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: 'semi-bold';
  font-weight: 600;
  src: local('IBMPlexSans-SemiBold'), local('IBMPlexSans-SemiBold'),
  url('../fonts/IBMPlexSans-SemiBold.woff') format('woff');
}

/* Inter-Regular */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local('Inter-Regular'), local('Inter-Regular'),
  url('../fonts/Inter-Regular.woff') format('woff');
}

/* Inter-Regular */
@font-face {
  font-family: 'Inter';
  font-style: 'semi-bold';
  font-weight: 600;
  src: local('Inter-SemiBold'), local('Inter-SemiBold'),
  url('../fonts/Inter-SemiBold.woff') format('woff');
}


/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat Light'), local('Montserrat-Light'),
  url('../fonts/montserrat-v14-latin-300.woff') format('woff');
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* overrides default bootstrap property in joyride tooltip */
.react-joyride__tooltip button:focus {
  outline: 0 !important;
}
