.leaflet-control {
  .leaflet-control-draw-measure {
    width: 30px;
    height: 30px;

    &:hover {
      width: 30px;
      height: 30px;
    }
  }
  &.enabled {
    .leaflet-control-draw-measure {
      background-image: url('~assets/images/measure/cancel.png');
    }
  }
  &:not(.enabled) {
    .leaflet-control-draw-measure {
      background-image: url('~assets/images/measure/measure-control.png');
    }
  }
}
