.leaflet-popup {
  height: 0;
  margin: 0 0 20px 0;

  &-content-wrapper {
    border-radius: 3px;
    position: relative;
    box-shadow: none;
  }

  &-content p {
    margin: 0;
  }

  &-tip-container {
    display: none;
  }

  &-content {
    margin: 0;

    &::after {
      z-index: -1;
      content: '';
      position: absolute;
      top: -9px;
      left: 50%;
      width: 20px;
      height: 20px;
      transform: translateX(-50%) rotate(45deg);
      background-color: #fff;
      box-shadow: -5px -5px 10px -3px rgba(0, 0, 0, 0.15);
    }
  }

  a.leaflet-popup-close-button {
    top: 5px;
    right: 5px;
    font-size: 30px;
    font-weight: 300;
    color: #e5e5e5;
  }
}
.leaflet-fade-anim .leaflet-popup {
  transition: none;
}

.leaflet-top.leaflet-left>.leaflet-control {
  clear: right;
  float: left;
  border: none !important;
}
