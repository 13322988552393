.leaflet-control-search .search-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  float: left;
  list-style: none;
  padding-left: 0;
  min-width: 120px;
  max-height: 122px;
  background-color: rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  overflow-x: hidden;
  cursor: pointer;
}
