.extra-layers-btn {
  padding: 0;
  width: 30px;
  height: 30px;
  background: url('~assets/images/geometries/layers.png') #fff no-repeat;
  background-size: 100%;

  .geometries-list {
    width: 280px;
  }
}
