.geom-control-btn {
  padding: 0;
  width: 30px;
  height: 30px;
  background: url('~assets/images/geometries/path_icon.svg') #fff no-repeat;
  background-size: 100%;
}

.geometries-list {
  border-radius: 5px;
  left: 0;
  font-weight: 700;
  margin: 0;
  padding: 10px;
  position: relative;
  top: 30px;
  background: white;
  width: 180px;

  label:not(.leaflet-control-layers-group-label) {
    display: flex;
    align-items: center;
  }

  .leaflet-control-layers-group-label {
    text-align: center;
  }

  .leaflet-control-layers-selector {
    margin-right: 5px;
  }

  span {
    word-break: break-all;
  }
}
